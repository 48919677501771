import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import Akihiko from './router/Router';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import keycloak from './services/authentication/Keycloak'
import {jwtReqInterceptor, jwtResInterceptor} from './helpers/interceptor'
import { expireSession } from './services/authentication/SessionService';
import './localization/i18next'
import { Provider } from 'react-redux';
import store from './redux/store';

let options = {onLoad: 'check-sso', checkLoginIframe: false}

const root = ReactDOM.createRoot(document.getElementById('root'));

jwtReqInterceptor();
// jwtResInterceptor();

root.render(
  <ReactKeycloakProvider 
    // onEvent={(evt) => {
    //   if (evt == 'onTokenExpired') {expireSession()}
    //   if (evt == 'onAuthRefreshError') {expireSession()}
    //   if (evt == 'onAuthError') {expireSession()}
    // }} 
    initOptions={options} 
    authClient={keycloak} 
    LoadingComponent={<div></div>}
  >
     <Provider store={store}>
      <BrowserRouter>
        <Akihiko/>
      </BrowserRouter>
    </Provider>
  </ReactKeycloakProvider>
);
