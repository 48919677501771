import { CardsFull, On_Click_Card, PageFull, PageTitle, PageTools, ThemeContext, logo } from 'monica-alexandria'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

let config = require('../../../../config/index').config

export default function PANEL(props) {

  const {t} = useTranslation()
  const {theme} = useContext(ThemeContext);

  const erisedURL = config.erised;
  const griphookURL = config.griphook;
  const loisURL = config.lois;
  const dailyprofitURL = config.dailyprofit;
  const launchpadURL = config.fahed;

  return (
    <PageFull>
        <PageTitle>
            <h1>{t("Admin Portal")}</h1>
        </PageTitle>
        <CardsFull>
          <On_Click_Card onClick={()=>window.location.assign(`${erisedURL}/el/akihiko/erised`)} imgSmall={logo.erised[theme]} title="Platform"/>
          <On_Click_Card onClick={()=>window.location.assign(`${griphookURL}/el/akihiko/griphook`)} imgSmall={logo.griphook[theme]} title="Payments"/>
          <On_Click_Card onClick={()=>window.location.assign(`${loisURL}/el/akihiko/lois`)} imgSmall={logo.lois[theme]} title="Adsnap"/>
          <On_Click_Card onClick={()=>window.location.assign(`${dailyprofitURL}/el/akihiko/dailyprofit`)} imgSmall={logo.dailyprofit[theme]} title="Questad"/>
          <On_Click_Card onClick={()=>window.location.assign(`${launchpadURL}/el/akihiko/fahed`)} imgSmall="" title="Launchpad"/>
        </CardsFull>
    </PageFull>
  )
}
