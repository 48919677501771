export const config = {
          BACKEND_URL:  process.env.REACT_APP_BACKEND_PROTOCOL +
                        process.env.REACT_APP_BACKEND_HOST +
                        process.env.REACT_APP_BACKEND_PORT,

  AKIHIKO_BACKEND_URL:  process.env.REACT_APP_AKIHIKO_BACKEND_PROTOCOL +
                        process.env.REACT_APP_AKIHIKO_BACKEND_HOST +
                        process.env.REACT_APP_AKIHIKO_BACKEND_PORT,

ERISED_BACKEND_URL: process.env.REACT_APP_ERISED_BACKEND_PROTOCOL +
                        process.env.REACT_APP_ERISED_BACKEND_HOST +
                        process.env.REACT_APP_ERISED_BACKEND_PORT,

                ERISED: process.env.REACT_APP_ERISED_FRONTEND_PROTOCOL +
                        process.env.REACT_APP_ERISED_FRONTEND_HOST +
                        process.env.REACT_APP_ERISED_FRONTEND_PORT,

              NIFFLER:  process.env.REACT_APP_NIFFLER_FRONTEND_PROTOCOL +
                        process.env.REACT_APP_NIFFLER_FRONTEND_HOST +
                        process.env.REACT_APP_NIFFLER_FRONTEND_PORT,

              SENTINEL:  process.env.REACT_APP_SENTINEL_FRONTEND_PROTOCOL +
                        process.env.REACT_APP_SENTINEL_FRONTEND_HOST +
                        process.env.REACT_APP_SENTINEL_FRONTEND_PORT,

                FAHED:  process.env.REACT_APP_FAHED_FRONTEND_PROTOCOL +
                        process.env.REACT_APP_FAHED_FRONTEND_HOST +
                        process.env.REACT_APP_FAHED_FRONTEND_PORT,

                MONICA: process.env.REACT_APP_MONICA_FRONTEND_PROTOCOL +
                        process.env.REACT_APP_MONICA_FRONTEND_HOST +
                        process.env.REACT_APP_MONICA_FRONTEND_PORT,
                minio:  process.env.REACT_APP_MINIO_PROTOCOL +
                        process.env.REACT_APP_MINIO_HOST,
                erised: process.env.REACT_APP_ERISED_FRONTEND_PROTOCOL +
                        process.env.REACT_APP_ERISED_FRONTEND_HOST +
                        process.env.REACT_APP_ERISED_FRONTEND_PORT,
                akihiko: process.env.REACT_APP_AKIHIKO_FRONTEND_PROTOCOL +
                        process.env.REACT_APP_AKIHIKO_FRONTEND_HOST +
                        process.env.REACT_APP_AKIHIKO_FRONTEND_PORT,
                griphook: process.env.REACT_APP_GRIPHOOK_FRONTEND_PROTOCOL +
                        process.env.REACT_APP_GRIPHOOK_FRONTEND_HOST +
                        process.env.REACT_APP_GRIPHOOK_FRONTEND_PORT,
                lois: process.env.REACT_APP_LOIS_FRONTEND_PROTOCOL +
                        process.env.REACT_APP_LOIS_FRONTEND_HOST +
                        process.env.REACT_APP_LOIS_FRONTEND_PORT,
                dailyprofit: process.env.REACT_APP_DAILYPROFIT_FRONTEND_PROTOCOL +
                        process.env.REACT_APP_DAILYPROFIT_FRONTEND_HOST +
                        process.env.REACT_APP_DAILYPROFIT_FRONTEND_PORT,
                fahed: process.env.REACT_APP_FAHED_FRONTEND_PROTOCOL +
                        process.env.REACT_APP_FAHED_FRONTEND_HOST +
                        process.env.REACT_APP_FAHED_FRONTEND_PORT
}
