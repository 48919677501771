import axios from 'axios';
import store from '../redux/store';
import { expireSession } from '../services/authentication/SessionService';

export function jwtReqInterceptor() {

    axios.interceptors.request.use(request => {

        const token = store.getState()?.user?.token;

        request.headers.Authorization = `${token}`;

        return request;
    });
}

export function jwtResInterceptor() {

    axios.interceptors.response.use(response => {
        if (response?.data?.status == 401) {
            expireSession();
        }
        return response;
        }
)}
