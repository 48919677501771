import axios from 'axios';

const k_protocol = process.env.REACT_APP_KEYMAKER_PROTOCOL;
const k_url = process.env.REACT_APP_KEYMAKER_HOST;
const k_port = process.env.REACT_APP_KEYMAKER_PORT;

const protocol = process.env.REACT_APP_AKIHIKO_BACKEND_PROTOCOL;
const url = process.env.REACT_APP_AKIHIKO_BACKEND_HOST;
const port = process.env.REACT_APP_AKIHIKO_BACKEND_PORT;

const getTranslations = (args) => {

    let data = {}
    if(args?.key) data.key = args.key;
    if(args?.client) data.client = args.client;
    if(args?.lang) data.lang = args.lang;

    return new Promise((resolve, reject) => {

        axios.post(`${k_protocol}${k_url}${k_port}/translations/getTranslations`, {
            data: data
        })
            .then(res => {
                resolve(res);
            })
            .catch(() => {
                resolve(false);
            })
    })
}

const updateTranslations = (lang, client, newTranslations) => {

    return new Promise((resolve, reject) => {
        axios.post(`${k_protocol}${k_url}${k_port}/translations/updateTranslations`,{
            lang,
            client,
            newTranslations
        })
            .then(res => {
                resolve(res);
            })
            .catch(() => {
                resolve(false);
            })
    })
}

const findMissing = () => {
    return new Promise((resolve, reject) => {
        axios.get(`${k_protocol}${k_url}${k_port}/translations/findMissing?client=${process.env.REACT_APP_NAME}`)
            .then(res => {
                resolve(res);
            })
            .catch(() => {
                resolve(false);
            })
    })
}


export {getTranslations, findMissing, updateTranslations}